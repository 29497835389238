<template>
    <!-- 消息通知详情页 -->
    <div>
        <Details :title="'消息通知'"  :articleID="articleID"/>
    </div>
</template>

<script>
    import Details from '../comm/details.vue'
    export default {
        components:{
            Details
        },
        computed:{
            articleID(){
                return this.$route.params.id
            }
        },
        // watch:{
        //     'this.$route.params.id':{
        //         handler(newValue){
        //             this.articleID = newValue
        //             console.log(newValue);
        //         }
        //     }
        // },
        created(){
            // console.log(this.$route.params.id);
        }
    }
</script>

<style scoped>

</style>